body {
  font-family: "Quicksand", sans-serif;
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  background-color: #e6e6e6;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-left: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.title {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
}

.menu {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}

.menu-item {
  padding-left: 10px;
  padding-right: 10px;
  height: 65px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  color: black !important;
  text-decoration: none !important;
}

.menu-item:hover {
  background-color: #e6e6e6;
}

.category {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  padding: 14px;
}

.product {
  padding: 5px;
  box-sizing: border-box;
}

.product-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
}

.product-line {
  display: flex;
}

.product-details {
  flex: 1 1 0px;
}

.product-name {
  font-weight: 600;
}

.product-price {
}

.product-button-container {
  text-align: right;
}

.footer {
  text-align: center;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 30px;
  border-top: 1px solid #cccccc;
}
